<template>
  <div>
    <br />
    <div class="row row-sm">
      <div class="col-3">
        <label for="">Client <span class="text-danger">*</span> </label>
        <select
          v-model="filter.customer_reference"
          class="form-select"
          required
        >
          <option selected value="--">--</option>
          <option
            v-for="(customer, index) in customers"
            :key="index++"
            :value="customer.reference"
            :style="
              customer.blocked || customer.fullName.includes('Bloqué')
                ? { color: 'red' }
                : ''
            "
          >
            {{ customer.fullName }} {{ customer.society }}
          </option>
        </select>
      </div>

      <div class="col">
        <label for="">Du <span class="text-danger">*</span> </label>
        <input v-model="filter.startDate" type="date" class="form-control" />
      </div>
      <div class="col">
        <label for=""> Au <span class="text-danger">*</span> </label>
        <input v-model="filter.endDate" type="date" class="form-control" />
      </div>

      <div class="col-2">
        <label for=""> État de paiement </label>
        <select v-model="filter.paymentStatus" class="form-select">
          <option value="--">--</option>
          <option value="Payé">Payé</option>
          <option value="Impayé">Impayé</option>
        </select>
      </div>
    </div>
    <br />
    <div class="row justify-content-end">
      <div class="col-auto">
        <button @click="toFilter(filter)" class="btn btn0 btn-secondary">
          <i class="bi bi-funnel"></i> Rechercher
        </button>
      </div>

      <div class="col-auto">
        <button @click="refresh()" class="btn btn-secondary">
          <i class="bi bi-arrow-clockwise"></i>
          Actualiser
        </button>
      </div>
    </div>
    <br />
    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Client</th>
          <th scope="col">Date</th>
          <th scope="col" class="text-end">M. Total</th>
          <th scope="col" class="text-end">Total Qté</th>
          <th scope="col" class="text-end">M. Payé</th>
          <th scope="col" class="text-end">M. Impayé</th>
          <th scope="col">Créer par</th>
          <th scope="col"></th>
          <th scope="col"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(sale, index) in sales.filter((p) => !p.validated)"
          :key="index++"
        >
          <td>
            {{ index }}
          </td>
          <td>
            {{ sale.reference }}
          </td>
          <td>
            <span>
              {{ sale.customerFullName }}
            </span>
          </td>
          <td>{{ sale.date }}</td>
          <td class="text-end">
            <span v-if="sale.totalPriceTTC" class="text-primary">
              {{ sale.totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span v-if="sale.totalQuantity >= 0" class="text-secondary">
              {{ sale.totalQuantity | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span class="text-success">
              +
              {{ sale.amountPaid | toFixedWithSpace }}
            </span>
          </td>
          <td class="text-end">
            <span v-if="sale.amountUnpaid" class="text-danger">
              -
              {{ sale.amountUnpaid | toFixedWithSpace }}
            </span>
          </td>

          <td>
            <span v-if="sale.userName">
              {{ sale.userName }}
            </span>
          </td>
          <td v-if="sale.validated">
            <span class="px-2 py-1 bg-success text-white rounded">
              Validated
            </span>
          </td>

          <td v-if="!sale.validated">
            <span class="px-2 py-1 bg-warning rounded"> En Attente </span>
          </td>
          <td v-if="userRole == 'Admin'" class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'sales-details',
                  params: { reference: sale.reference },
                })
              "
              class="btn p-0 m-0"
            >
              <i class="bi bi-info-square p-0 m-0"></i>
            </button>
          </td>

          <td v-else-if="userRole == 'Magasinier'" class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'storekeeper-sales-details',
                  params: { reference: sale.reference },
                })
              "
              class="btn p-0 m-0"
            >
              <i class="bi bi-info-square p-0 m-0"></i>
            </button>
          </td>
        </tr>
      </tbody>
      <tfoot>
        <tr>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col" class="text-end">
            <span class="bg-primary py-1 px-2 rounded text-white">
              {{ totalPriceTTC | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span class="bg-secondary py-1 px-2 rounded text-white">
              {{ totalQuantity | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span class="bg-success py-1 px-2 rounded text-white">
              {{ totalPaid | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col" class="text-end">
            <span
              v-if="totalUnpaid"
              class="bg-danger py-1 px-2 rounded text-white"
            >
              {{ totalUnpaid | toFixedWithSpace }}
            </span>
          </td>
          <td scope="col"></td>
          <td scope="col"></td>
          <td scope="col"></td>
        </tr>
      </tfoot>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
      filter: {
        startDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
        endDate: new Date(new Date().setFullYear(new Date().getFullYear()))
          .toLocaleString("en-CA")
          .split(",")[0],
      },
    };
  },
  computed: {
    ...mapGetters("sale", {
      sales: "getAll",
      totalQuantity: "getSalesTotalQuantity",
      totalPriceTTC: "getSalesTotalPriceTTC",
      totalPaid: "getSalesTotalPaid",
      totalUnpaid: "getSalesTotalUnpaid",
    }),
    ...mapGetters("customer", {
      customers: "getAll",
    }),
    ...mapGetters("auth", {
      userRole: "getUserRole",
    }),
  },
  mounted() {
    this.$store.dispatch("sale/getAll");
    this.$store.dispatch("customer/getAll");
  },
  methods: {
    async search(value) {
      return await this.$store.commit("sale/search", value);
    },
    async refresh() {
      await this.$store.dispatch("sale/getAll");
      await this.$store.dispatch("customer/getAll");
    },
    async toFilter(data) {
      return await this.$store.dispatch("sale/filter", data);
    },
  },
  filters: {
    toFixedWithSpace: (value) => {
      return parseFloat(value)
        .toFixed(2)
        .replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1 ");
    },
  },
};
</script>
